<template>
  <div>
    <h1>文件版本记录</h1>
    <table border="1">
      <thead>
        <tr>
          <th>ID</th>
          <th>缩略图</th>
          <th>文件名</th>
          <th>系统版本</th>
          <th>自定义版本</th>
          <th>工艺路线</th>
          <th>材质</th>
          <th>板厚</th>
          <th>特殊要求</th>
          <th>折弯方向</th>
          <th>压延方向</th>
          <th>客户</th>
          <th>单据号</th>
          <th>创建人</th>
          <th>创建时间</th>
          <th>审核人</th>
          <th>审核时间</th>
          <th>发布人</th>
          <th>发布时间</th>
          <th>发布路径</th>
          <th>是否有效</th>
          <th>备注</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in records" :key="record.id">
          <td>{{ record.id }}</td>
          <td>
            <img :src="record.thumbnailURL" alt="缩略图" width="200" height="200" />
          </td>
          <td>{{ record.fileName }}</td>
          <td>{{ record.version }}</td>
          <td>{{ record.customVersion }}</td>
          <td>{{ record.cutMethodName }}</td>
          <td>{{ record.textureName }}</td>
          <td>{{ record.plateThick }}</td>
          <td>{{ record.specialInstructions }}</td>
          <td>{{ record.bendingDirection }}</td>
          <td>{{ record.calenderingDirection }}</td>
          <td>{{ record.customerName }}</td>
          <td>{{ record.invoiceNo }}</td>
          <td>{{ record.creatorName }}</td>
          <td>{{ record.createTime }}</td>
          <td>{{ record.checkerName }}</td>
          <td>{{ record.checkTime }}</td>
          <td>{{ record.publisherName }}</td>
          <td>{{ record.publishTime }}</td>
          <td>{{ record.publishedPath }}</td>
          <td>{{ record.isEnabled }}</td>
          <td>{{ record.remark }}</td>
          <td>
            <button @click="downloadFile(record.id, record.fileName)">下载</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      records: []
    };
  },
  created() {
    this.fetchFileVersions();
  },
  watch: {
    '$route'(to, from) {
      // 当路由变化时重新获取文件详情
      this.fetchFileVersions();
    }
  },
  methods: {
    async fetchFileVersions() {
      try {
        const fileName = this.$route.params.fileName;
        const response = await axios.get(`/api/FileVersion/${fileName}`);
        this.records = response.data;
      } catch (error) {
        console.error("获取文件版本记录时出错:", error);
      }
    },
    async downloadFile(fileId, fileName) {
      // 实现下载逻辑
        let response = await axios.get(`/api/FileDetails/download/${fileId}`, {
          responseType: 'blob'
        });       
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      // 这里可以调用下载接口
    }
  }
};
</script>
  
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}</style>