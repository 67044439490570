<template>
    <div v-if="message" class="alert-container">
      <div class="alert">
        <p>{{ message }}</p>
        <button @click="close">关闭</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CustomAlert',
    props: {
      message: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        visible: true
      };
    },
    mounted() {
      if (this.message==""||this.message==null||this.message==undefined) this.visible = false;
      else this.visible = true;
      this.setAutoClose();
    },
    methods: {
      close() {
        this.visible = false;
        this.$emit('close');
      },
      setAutoClose() {
        setTimeout(() => {
          this.close();
        }, 10000); // 10秒后自动关闭
      }
    }
  };
  </script>
  
  <style scoped>
  .alert-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .alert {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .alert p {
    margin-bottom: 10px;
  }
  
  .alert button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
  }
  
  .alert button:hover {
    background-color: #45a049;
  }
  </style>