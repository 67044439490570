<template>
    <div class="register-container">
      <h2>注册</h2>
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="username">用户名：</label>
          <input type="text" id="username" v-model="username" required>
        </div>        
        <div class="form-group">
          <label for="phone">手机号码：</label>
          <input type="text" id="phone" v-model="phone" required>
        </div>
        <div class="form-group">
          <label for="password">密码：</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <div class="form-group">
          <label for="confirmPassword">确认密码：</label>
          <input type="password" id="confirmPassword" v-model="confirmPassword" required>
        </div>

        <button type="submit">注册</button>
      </form>
      <p v-if="error" class="error">{{ error }}</p>
      <p v-if="success" class="success">{{ success }}</p>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import axios from 'axios';
  
  const username = ref('');
  const password = ref('');
  const confirmPassword = ref('');
  const phone = ref('');
  const error = ref('');
  const success = ref('');
 
  
  const handleSubmit = async () => {
    error.value = '';
    success.value = '';
  
    if (password.value !== confirmPassword.value) {
      error.value = '两次输入的密码不一致';
      return;
    }
  
    try {
      const response = await axios.post('/api/register', {
        username: username.value,
        password: password.value,
        confirmPassword: confirmPassword.value,
        phone: phone.value
      });
  
      success.value = '注册成功';
      setTimeout(() => {
        this.$router.push('/login');
      }, 2000);
    } catch (err) {
      if (err.response) {
        error.value = err.response.data.message;
      } else {
        error.value = '注册时发生错误，请稍后再试';
      }
    }
  };
  </script>
  
  <style scoped>
  .register-container {
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 5px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .error {
    color: red;
    margin-top: 10px;
  }
  
  .success {
    color: green;
    margin-top: 10px;
  }
  </style>