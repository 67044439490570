import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from './components/HelloWorld.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import FileDetail from './components/FileDetail.vue';
import FileVersionRecords from './components/FileVersionRecords.vue';
import Home from './components/Home.vue';
import Settings from './components/Settings.vue';
Vue.use(Router);

const routes = [
  { path: '/', component: HelloWorld },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/filedetail/:id', name: 'filedetail',component: FileDetail },
  { path: '/FileVersionRecords/:fileName', name: 'FileVersionRecords',component: FileVersionRecords },
  { path: '/home', component: Home },
  { path: '/Settings', component: Settings },
  

];

export default new Router({
  mode: 'history',
  routes
});