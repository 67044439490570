<template>
  <ul class="directory-tree">
    <li v-for="directory in directories" :key="directory.id">
      <div class="directory-item" @click="handleClick(directory)">
        <span v-if="directory.children && directory.children.length > 0" class="toggle-icon">
          {{ directory.expanded ? '-' : '+' }}
        </span>
        <span>{{ directory.name }}</span>
      </div>
      <DirectoryTree v-if="directory.expanded" :directories="directory.children" @node-click="$emit('node-click', $event)" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DirectoryTree',
  props: {
    directories: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleClick(directory) {
      this.$emit('node-click', directory);
      if (directory.children && directory.children.length > 0) {
        directory.expanded = !directory.expanded;
      }
    }
  }
};
</script>

<style scoped>
.directory-tree {
  list-style-type: none;
  padding-left: 20px;
}

.directory-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.toggle-icon {
  margin-right: 5px;
  font-weight: bold;
}
</style>