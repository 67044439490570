<template>
  <div class="file-details">
    <h2>系统: {{ fileDetails.fileName }}</h2>
    <div class="file-content">
      <div class="file-thumbnail">
        <img v-if="thumbnailUrl" :src="thumbnailUrl" alt="File Thumbnail" />
        <p v-else>暂无缩略图</p>
      </div>
      <div class="file-info">
        <p><strong>文件大小:</strong> {{ fileDetails.fileSize }}</p>
        <p><strong>更新时间:</strong> {{ fileDetails.updateTime }}</p>
        <div class="file-memo">
          <h3>文件备注</h3>
          <textarea v-model="memo" @blur="updateMemo"></textarea>
        </div>
      </div>
    </div>
    <div class="file-details-sections">
      <div class="file-versions">
        <h3>相关版本</h3>
        <ul>
          <li v-for="version in versions" :key="version.id">
            版本 {{ version.versionNumber }} - {{ version.updateTime }}
          </li>
        </ul>
      </div>
      <div class="file-history">
        <h3>文件历史</h3>
        <ul>
          <li v-for="history in fileHistory" :key="history.id">
            {{ history.operation }} - {{ history.operationTime }} - {{ history.operator }}
          </li>
        </ul>
      </div>
      <div class="related-files">
        <h3>相关文件</h3>
        <ul>
          <li v-for="relatedFile in relatedFiles" :key="relatedFile.id">
            {{ relatedFile.fileName }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
// script 部分保持不变
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

// const route = useRoute();
// const fileId = route.params.id;

const fileDetails = ref({});
const versions = ref([]);
const fileHistory = ref([]);
const relatedFiles = ref([]);
const memo = ref('');
const thumbnailUrl = ref('');

const fetchFileDetails = async () => {
  try {
    const response = await axios.get(`/api/filedetails/${fileId}`);
    fileDetails.value = response.data;
    memo.value = response.data.memo;
  } catch (error) {
    console.error('Error fetching file details:', error);
  }
};

const fetchThumbnail = async () => {
  try {
    const response = await axios.get(`/api/filedetails/${fileId}/thumbnail`, {
      responseType: 'blob'
    });
    thumbnailUrl.value = URL.createObjectURL(response.data);
  } catch (error) {
    console.error('Error fetching thumbnail:', error);
    thumbnailUrl.value = ''; // 设置为空字符串，表示没有缩略图
  }
};

const fetchFileVersions = async () => {
  try {
    const response = await axios.get(`/api/filedetails/${fileId}/versions`);
    versions.value = response.data;
  } catch (error) {
    console.error('Error fetching file versions:', error);
  }
};

const fetchFileHistory = async () => {
  try {
    const response = await axios.get(`/api/filedetails/${fileId}/history`);
    fileHistory.value = response.data;
  } catch (error) {
    console.error('Error fetching file history:', error);
  }
};

const fetchRelatedFiles = async () => {
  try {
    const response = await axios.get(`/api/filedetails/${fileId}/related`);
    relatedFiles.value = response.data;
  } catch (error) {
    console.error('Error fetching related files:', error);
  }
};

const updateMemo = async () => {
  try {
    await axios.put(`/api/filedetails/${fileId}/memo`, memo.value);
  } catch (error) {
    console.error('Error updating file memo:', error);
  }
};

onMounted(() => {
  fetchFileDetails();
  fetchFileVersions();
  fetchFileHistory();
  fetchRelatedFiles();
  fetchThumbnail();
});
</script>

<style scoped>
.file-details {
  padding: 20px;
}

.file-content {
  display: flex;
  margin-bottom: 20px;
}

.file-thumbnail {
  flex: 0 0 300px;
  margin-right: 20px;
}

.file-thumbnail img {
  max-width: 100%;
  max-height: 300px;
  object-fit: contain;
}

.file-info {
  flex: 1;
}

.file-memo {
  margin-top: 20px;
}

.file-memo textarea {
  width: 100%;
  height: 100px;
}

.file-details-sections {
  display: flex;
  flex-wrap: wrap;
}

.file-versions, .file-history, .related-files {
  flex: 1 1 30%;
  margin-right: 20px;
  margin-bottom: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}
</style>