<template>

  <div class="home">

    <div class="sidebar">
      <DirectoryTree :directories="directories" @node-click="onNodeClick" />
    </div>
    <div class="content">
      <h2>文件列表</h2>
      <table class="file-table" v-if="files.length > 0">
        <thead>
          <tr>
            <th>文件名</th>
            <th>后缀名</th>
            <th>系统版本</th>
            <th>自定义版本</th>
            <th>工艺路线</th>
            <th>材质</th>
            <th>板厚</th>
            <th>特殊要求</th>
            <th>折弯方向</th>
            <th>压延方向</th>
            <th>客户</th>
            <th>单据号</th>
            <th>创建人</th>
            <th>创建时间</th>
            <th>审核人</th>
            <th>审核时间</th>
            <th>发布人</th>
            <th>发布时间</th>
            <th>发布路径</th>
            <th>是否有效</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.id">
            <td>{{ file.fname | escapeHtml }}</td>
            <td>{{ file.ftype | escapeHtml }}</td>
            <td>{{ file.version | escapeHtml }}</td>
            <td>{{ file.udefinedVersion	 | escapeHtml }}</td>
            <td>{{ file.cutmethodName	 | escapeHtml }}</td>
            <td>{{ file.textureName	 | escapeHtml }}</td>
            <td>{{ file.plateThick}} </td>
            <td>{{ file.specialInstructions}} </td>	
            <td>{{ file.bendingDirection	 | escapeHtml}} </td>	
            <td>{{ file.calenderingDirection	 | escapeHtml}} </td>	
            <td>{{ file.calenderingDirection	 | escapeHtml}} </td>
            <td>{{ file.invoiceNo		 | escapeHtml}} </td>
            <td>{{ file.invoiceNo		 | escapeHtml}} </td>
            <td>{{ file.adate | escapeHtml }}</td>
            <td>{{ file.adate | escapeHtml }}</td>
            <td>{{ file.cdate | escapeHtml }}</td>
            <td>{{ file.cdate | escapeHtml }}</td>
            <td>{{ file.pdate | escapeHtml }}</td>
            <td>{{ file.publishedPath	 | escapeHtml }}</td>
            <td>{{ file.isEnabled | escapeHtml }}</td>
            <td>
              <button @click="viewDetails(file.id)">查看详情</button>
              <button @click="viewFileVersionRecords(file.fname+'.'+file.ftype)">查看版本</button>
              <button @click="downloadFile(file)">下载</button>
              <button @click="deleteFile(file)" v-if="userInfo.isSuper">删除</button>
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>暂无文件</p>
      <custom-alert :message="alertMessage" @close="alertMessage = ''" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DirectoryTree from '@/components/DirectoryTree.vue';
import CustomAlert from './CustomAlert.vue';

export default {
  name: 'Home',
  components: {
    DirectoryTree,
    CustomAlert
  },
  data() {
    return {
      directories: [],
      files: [],
      userInfo: {},
      alertMessage: ''
    };
  },
  created() {
    this.fetchDirectories();
    this.getUserInfo();
  },
  methods: {
    async fetchDirectories() {
      try {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const response = await axios.get(`/api/home/categorytree?userid=${userId}`);

        this.directories = this.initializeDirectories(response.data);
      } catch (error) {
        console.error('Error fetching directories:', error);
        this.showCustomAlert('获取目录列表失败，请稍后再试。');
      }
    },
    async getUserInfo() {
      try {
        const userId = JSON.parse(localStorage.getItem("user")).id; // 假设我们在登录时存储了用户ID
        const response = await axios.get(`/api/home/user-info?userId=${userId}`);
        this.userInfo = response.data; 
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    },
    initializeDirectories(data) {
      return data.map(dir => ({
        ...dir,
        expanded: false,
        children: dir.children ? this.initializeDirectories(dir.children) : []
      }));
    },
    async onNodeClick(directory) {
      const userId = JSON.parse(localStorage.getItem('user')).id;
      try {
        const response = await axios.get(`/api/home/files?categoryId=${directory.id}&userId=${userId}`);
        this.files = response.data;
      } catch (error) {
        console.error('Error fetching files:', error);
        this.showCustomAlert('获取文件列表失败，请稍后再试。');
      }
    },
    logout() {
      console.log(this); // 检查 this 的值
      try {
        // 清除本地存储中的用户ID和token
        localStorage.removeItem('userid');
        localStorage.removeItem('token');

        // 跳转到登录页面
        this.$router.push('/login');
      } catch (error) {
        console.error('登出失败:', error);
        this.showCustomAlert('登出失败，请重试');
      }
    },
    async downloadFile(file) {
      try {
        let response = await axios.get(`/api/FileDetails/download/${file.id}`, {
          responseType: 'blob'
        });       
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.fname);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
        this.showCustomAlert('下载文件时发生错误');
      }
    },
    async viewDetails(fileId) {
      try {
        this.$router.push({ name: 'filedetail', params: { id: fileId } });
      } catch (error) {
        console.error('查看文件时发生错误:', error);
        this.showCustomAlert('查看文件时发生错误');
      }
    },
    async viewFileVersionRecords(fileName) {
      try {
        this.$router.push({ name: 'FileVersionRecords', params: { fileName: fileName } });
      } catch (error) {
        console.error('查看文件时发生错误:', error);
        this.showCustomAlert('查看文件时发生错误');
      }
    },
    handleFileAction(file) {
      // 处理文件操作，例如下载、删除等
      console.log('File action:', file);

      //alert(`操作文件: ${file.name}`);
    },
    showCustomAlert(message) {
      if (message!=""&&message!=null)
      this.alertMessage = message;
    }
  }
};
</script>

<style scoped>
.home {
  display: flex;
  height: 100vh;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f0f0f0;
}
.sidebar {
  width: 300px;
  background-color: #f4f4f4;
  overflow-y: auto;
  border-right: 1px solid #ddd;
}

.content {
  flex: 1;
  padding: 20px;
}

.file-table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.file-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.file-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.file-table tr:hover {
  background-color: #f1f1f1;
}

.file-table button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.file-table button:hover {
  background-color: #45a049;
}
</style>