import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    token: null
  },
  mutations: {
    login(state, payload) {
      state.user = payload.user;
      state.token = payload.token;
    },
    logout(state) {
      state.user = null;
      state.token = null;
    }
  },
  actions: {
    login({ commit }, { username, password }) {
      // 这里可以添加处理登录逻辑，如调用API等
      console.log('Logging in with:', username, password);
      commit('login', { user: { username }, token: 'your-token' });
    },
    logout({ commit }) {
      commit('logout');
    }
  }
});

export default store;