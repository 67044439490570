<template>
  <div class="file-detail">
    <custom-alert :message="alertMessage" @close="alertMessage = ''" />
    <div class="top-section">
      <div class="thumbnail-container">
        <div v-if="thumbnailBase64" class="thumbnail">
          <img :src="thumbnailBase64" alt="Dxf Thumbnail" />
        </div>
        <div v-else class="no-thumbnail">暂无缩略图</div>
      </div>
      <div class="file-info">
        <h2>文件详情</h2>
        <div class="file-info-container">
          <!-- <p><strong>文件名:</strong> {{ file.fname }}</p>
        <p><strong>版本:</strong> {{ file.version }}</p>
        <p><strong>大小:</strong> {{ file.bytes }} KB</p>
        <p><strong>修改日期:</strong> {{ file.adate }}</p> -->
          <div class="left-info">
            <p><strong>文件名:</strong> {{ file.fname }}</p>
            <p><strong>版本:</strong> {{ file.version }}</p>
            <p><strong>大小:</strong> {{ file.bytes }} KB</p>
            <p><strong>创建人:</strong> {{ file.userName }}</p>
            <p><strong>客户:</strong> {{ file.custName }}</p>
            <p><strong>自定义版本:</strong> {{ file.udefinedVersion }}</p>
            <p><strong>创建日期:</strong> {{ file.adate }}</p>
          </div>
          <div class="right-info">
            <p><strong>单据号:</strong> {{ file.invoiceNo }}</p>
            <p><strong>工艺路线:</strong> {{ file.cutmethodName }}</p>
            <p><strong>材质:</strong> {{ file.textureName }}</p>
            <p><strong>板厚:</strong> {{ file.plateThick }}</p>
            <p><strong>特殊要求:</strong> {{ file.specialInstructions }}</p>
            <p><strong>折弯方向:</strong> {{ file.bendingDirection }}</p>
            <p><strong>压延方向:</strong> {{ file.calenderingDirection }}</p>
          </div>
        </div>
        <div class="actions">
          <button @click="downloadFile">下载</button>
          <button @click="deleteFile" v-if="userInfo.isSuper">删除</button>
        </div>
      </div>
    </div>
    <div class="bottom-section">
      <div class="tabs">
        <div :class="['tab', { active: activeTab === 'properties' }]" @click="activeTab = 'properties'">文件属性</div>
        <div :class="['tab', { active: activeTab === 'versions' }]" @click="activeTab = 'versions'">相关版本</div>
        <div :class="['tab', { active: activeTab === 'history' }]" @click="activeTab = 'history'">文件历史</div>
        <div :class="['tab', { active: activeTab === 'related' }]" @click="activeTab = 'related'">相关文件</div>
        <div :class="['tab', { active: activeTab === 'commmunications' }]" @click="activeTab = 'commmunications'">沟通记录
        </div>
        <div :class="['tab', { active: activeTab === 'memo' }]" @click="activeTab = 'memo'">备注</div>
      </div>
      <div class="tab-content">
        <div class="file-attributes">
          <div v-if="activeTab === 'properties'" class="['tab-pane', { active: activeTab === 'properties' }]">
            <div class="attribute-grid">
              <div v-for="(value, key) in properties" :key="key" class="attribute-item">
                <div class="attribute-label">{{ key }}:</div>
                <div class="attribute-value">{{ value }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div v-if="activeTab === 'versions'" class="['tab-pane', { active: activeTab === 'versions' }]">
          <table class="versions-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>版本号</th>
                <th>创建人</th>
                <th>创建时间</th>
                <th>说明</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="version in versionHistory" :key="version.id">
                <td>{{ version.id }}</td>
                <td>{{ version.version }}</td>
                <td>{{ version.userName }}</td>
                <td>{{ version.adate }}</td>
                <td>{{ version.memo1 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="activeTab === 'history'" class="['tab-pane', { active: activeTab === 'history' }]">

        </div>
        <div v-if="activeTab === 'related'" class="['tab-pane', { active: activeTab === 'related' }]">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>文件名称</th>
                <th>版本号</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="file in relatedFiles" :key="file.id">
                <td>{{ file.id }}</td>
                <td>{{ file.rfname }}</td>
                <td>{{ file.rversion }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="activeTab === 'commmunications'" class="['tab-pane', { active: activeTab === 'commmunications' }]">
          <div class="commmunication-list">
            <ul>
              <li v-for="commmunication in commmunications" :key="commmunication.id">
                【 {{ commmunication.adder }}】: {{ commmunication.record }} at {{ commmunication.adate }}
              </li>
            </ul>
          </div>
        </div>
        <div v-if="activeTab === 'memo'" class="['tab-pane', { active: activeTab === 'memo' }]">
          <div v-if="!isEditing" @click="isEditing = true;">
            {{ this.file.memo1 || "暂无内容" }}
          </div>
          <textarea v-else v-model="note" @blur="isEditing = false" @keyup.enter="isEditing = false"
            @focus="onFocus"></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CustomAlert from './CustomAlert.vue'; // 假设 CustomAlert.vue 在同一目录下

export default {
  name: 'FileDetail',
  components: {
    CustomAlert
  },
  data() {
    return {
      file: {},
      userInfo: {},
      properties: {},
      thumbnailBase64: '',
      alertMessage: '', // 初始化为空，设置此值以触发弹窗
      activeTab: 'properties', // 默认激活的相关版本页签
      relatedFiles: [
      ],
      versionHistory: [
      ],
      commmunications: [
      ],
      note: '暂无内容',
      isEditing: false
    };
  },
  created() {
    this.getFileById();
    this.getUserInfo();
    this.getFileInfo();
    this.getFileProperties();
    this.getRelatedFiles();
    this.getFileHistory();
    this.getVersionHistory();
    this.getFileCommmunications();
  },
  watch: {
    '$route'(to, from) {
      // 当路由变化时重新获取文件详情
      this.getFileById();
      this.getUserInfo();
      this.getFileInfo();
      this.getFileProperties();
      this.getRelatedFiles();
      this.getFileHistory();
      this.getVersionHistory();
      this.getFileCommmunications();
    }
  },
  methods: {
    async getFileById() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}`, '获取文件详情失败，请稍后再试。');
        this.file = response.data;
        if (this.file.id) {
          // await this.getDxfThumbnail(this.file.id);
          await this.generateThumbnail();
        }
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件信息失败，请稍后再试。');
      }
    },
    async getUserInfo() {
      try {
        const userId = JSON.parse(localStorage.getItem("user")).id;
        const response = await this.safeAxiosGet(`/api/home/user-info?userId=${userId}`, '获取用户信息失败，请稍后再试。');
        this.userInfo = response.data;
      } catch (error) {
        console.error('Error fetching user info:', error);
        this.showCustomAlert('获取用户信息失败，请稍后再试。');
      }
    },
    async getFileInfo() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}`, '获取文件详情失败，请稍后再试。');
        this.file = response.data;
        this.note = response.data.memo1 || "暂无内容";
        if (this.file.id) {
          // await this.getDxfThumbnail(this.file.id);
          await this.generateThumbnail();
        }
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件详情失败，请稍后再试。');
      }
    },
    async getFileProperties() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/FileDetails/${fileId}/properties`, '获取文件属性失败，请稍后再试。');
        this.properties = response.data;
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件属性失败，请稍后再试。');
      }
    },
    async getRelatedFiles() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}/related`, '获取文件详情失败，请稍后再试。');
        this.relatedFiles = response.data;
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取相关文件失败，请稍后再试。');
      }
    },
    async getFileHistory() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}`, '获取文件详情失败，请稍后再试。');
        this.file = response.data;
        if (this.file.id) {
          // await this.getDxfThumbnail(this.file.id);
          await this.generateThumbnail();
        }
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件操作历史失败，请稍后再试。');
      }
    },
    async getVersionHistory() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}/versions`, '获取文件详情失败，请稍后再试。');
        this.versionHistory = response.data;
        if (this.file.id) {
          // await this.getDxfThumbnail(this.file.id);
          await this.generateThumbnail();
        }
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件版本历史失败，请稍后再试。');
      }
    },
    async getFileCommmunications() {
      try {
        const fileId = this.$route.params.id;
        const response = await this.safeAxiosGet(`/api/filedetails/${fileId}/communications`, '获取文件详情失败，请稍后再试。');
        this.commmunications = response.data;
      } catch (error) {
        console.error('Error fetching file details:', error);
        this.showCustomAlert('获取文件操作历史失败，请稍后再试。');
      }
    },
    async downloadFile() {
      try {
        const response = await this.safeAxiosGet(`/api/FileDetails/download/${this.file.id}`, '下载文件时发生错误', { responseType: 'blob' });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.file.fname);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
        this.showCustomAlert('下载文件时发生错误');
      }
    },
    async deleteFile() {
      try {
        const response = await this.safeAxiosDelete(`/api/file/delete/${this.file.id}`, '删除文件时发生错误');
        this.showCustomAlert('文件删除成功');
        this.$router.push('/home'); // 删除成功后返回文件列表页面
      } catch (error) {
        console.error('Error deleting file:', error);
        this.showCustomAlert('删除文件时发生错误');
      }
    },
    async getDxfThumbnail(dxfId) {
      try {
        const response = await this.safeAxiosGet(`/api/FileDetails/${dxfId}/thumbnail`, '获取DXF缩略图失败，请稍后再试。');
        this.file.thumbnail = response.data.thumbnailUrl;
      } catch (error) {
        console.error('Error fetching DXF thumbnail:', error);
        this.showCustomAlert('获取DXF缩略图失败，请稍后再试。');
      }
    },
    async generateThumbnail() {
      try {
        const response = await this.safeAxiosGet(`/api/FileDetails/${this.file.id}/thumbnail`);
        this.thumbnailBase64 = response.data.thumbnailBase64;
      } catch (error) {
        console.error('生成缩略图失败:', error);
      }
    },
    async showCustomAlert(message) {
      if (message != "" && message != null)
        this.alertMessage = message;

    },
    safeAxiosGet(url, errorMessage, config = {}) {
      return axios.get(url, {
        ...config,
        headers: {
          'X-CSRF-Token': this.getCsrfToken(),
          ...config.headers
        }
      }).catch(error => {
        console.error(errorMessage, error);
        throw new Error(errorMessage);
      });
    },
    safeAxiosDelete(url, errorMessage) {
      return axios.delete(url, {
        headers: {
          'X-CSRF-Token': this.getCsrfToken()
        }
      }).catch(error => {
        console.error(errorMessage, error);
        throw new Error(errorMessage);
      });
    },
    getCsrfToken() {
      // 假设 CSRF 令牌存储在 localStorage 中
      return localStorage.getItem('csrfToken');
    },
    onFocus() {
      if (this.note === '暂无内容') {
        this.note = '';
      }
    },
    onBlur() {
      if (this.note.trim() === '') {
        this.note = '暂无内容';
      }
    },
  }
};
</script>
<style scoped>
.file-detail {
  padding: 20px;
}

.top-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.thumbnail-container {
  width: 30%;
  border: 1px solid #ffa500;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  max-height: 350px;
  background-color: black;
}

.thumbnail {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;

}

.thumbnail img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: auto;
}

.no-thumbnail {
  font-size: 16px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.file-info {
  width: 65%;
  padding: 20px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-left: 20px;
  text-align: left;
}

.file-info h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.file-info-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.left-info,
.right-info {
  width: 48%;
  padding: 10px;
  box-sizing: border-box;
}

.file-info p {
  margin: 5px 0;
}

.actions {
  margin-top: 20px;
}

.actions button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
  margin-right: 10px;
}

.actions button:hover {
  background-color: #45a049;
}

.bottom-section {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
}

.tab.active {
  border-bottom: 2px solid #4CAF50;
  color: #4CAF50;
}

.tab-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.file-attributes {
  margin-bottom: 20px;
}

.attribute-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* 每行两列 */
  gap: 10px;
}

.attribute-item {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.attribute-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.attribute-value {
  color: #333;
}

.tab-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.tab-content th,
.tab-content td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.tab-content th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.tab-content tr:hover {
  background-color: #f1f1f1;
}

.tab-content .empty-cell {
  color: #999;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .attribute-row {
    flex: 1 1 100%;
    /* 在小屏幕上每个属性项占一行 */
  }

  .attribute-item {
    flex: 1 1 100%;
    /* 在小屏幕上每个属性项占一行 */
  }

  .attribute-item-last {
    margin-right: 0;
    /* 在小屏幕上不需要调整右边距 */
  }
}

.commmunication-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.commmunication-list li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.commmunication-list li:last-child {
  border-bottom: none;
}

.commmunication-list li:hover {
  background-color: #f8f8f8;
}

.commmunication-list .username {
  font-weight: bold;
  color: #007bff;
  margin-right: 10px;
}

.commmunication-list .message {
  flex: 1;
}

.commmunication-list .timestamp {
  color: #999;
  font-size: 0.9em;
  margin-left: auto;
}

textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}</style>